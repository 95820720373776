<template>
<div class="container-fluid text-center mt-4">
<h1 class="text-sm">404 Page Not Found</h1>
<p>There's nothing here. If you believe this is a mistake, please contact the HFL team <a target="_blank" href="https://www.homeforlifedesign.com/contact">here.</a></p>
<button class="btn purple-btn" @click="navigateHome">Home</button>
</div>
  
 
</template>

<script>
export default {
    methods:{
        navigateHome: function(){
            // add hfl link here
            window.location.href = ""
        }
    }
}
</script>

<style>

</style>